import { useEffect, useState } from "react";
import { FloatingLabel, Spinner, Form, Image, Button } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import A4BMeetingToolkit from "@a4b/a4b-meeting-toolkit";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import { AssembleiaGetResponseEntity } from "../../entities/AssembleiaEntity";
import AssembleiaService from "../../services/AssembleiaService";

export default function ChamadaConvidado() {
	const assembleiaService = new AssembleiaService();

	const navigate = useNavigate();
	const { handleLogout } = useAuth();
	const { handleToast } = useToast();

	const [searchParams] = useSearchParams();
	const assembleiaId = searchParams.get("assembleiaId");
	const atoId = searchParams.get("atoId");
	const chamadaId = searchParams.get("chamadaId");
	const chamadaToken = searchParams.get("chamadaToken");
	const chamadaAdvogado = searchParams.get("chamadaAdvogado");

	const [formStatus, setFormStatus] = useState(1);
	const [formNome, setFormNome] = useState(localStorage.getItem("chamadaConvidadoNome") || "");

	const [data, setData] = useState<AssembleiaGetResponseEntity["Data"]>();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		assembleiaService.getPublica({ assembleiaId: Number(assembleiaId), chamadatoken: chamadaToken! }).then((resp: AssembleiaGetResponseEntity) => {
			if (resp.Result === 1 && resp.Data) {
				setData(resp.Data);
				setIsLoading(false);
			} else {
				if (resp.Result === 99) {
					handleLogout();
					navigate("/");
				}
				handleToast("Chamada", resp.Message, 5000, "warning");
				handleVoltar();
			}
		});
	}, []);

	function handleVoltar() {
		navigate(-1);
	}

	const ato = data?.atos.find((ato) => {
		return ato.id === Number(atoId);
	});
	const descricao = `${data?.clientes[0].clienteNome} ${data && data.clientes.length > 1 ? "e outros" : ""} - ${ato?.descricao}`;

	return (
		<div className="d-flex justify-content-center position-relative" style={{ height: "100dvh" }}>
			{formStatus === 0 ? (
				<>
					{isLoading && (
						<div className="position-absolute d-flex justify-content-center align-items-center flex-column bg-white" style={{ inset: 0, zIndex: 100 }}>
							<Spinner className="mb-2" />
							Carregando
						</div>
					)}
					<div style={{ display: "flex", flex: 1, justifyContent: "center", position: "relative" }}>
						<A4BMeetingToolkit
							id={String(chamadaId)}
							token={String(chamadaToken)}
							title={descricao}
							date={ato?.dataPrevista}
							participant={{
								id: generateUUID(),
								name: formNome,
								email: "teste@teste.com.br",
								role: "guest",
							}}
							features={{
								chat: true,
								participants: true,
								pip: true,
								screenShare: chamadaAdvogado === "1" ? true : false,
							}}
							config={{
								apiUrl: process.env.REACT_APP_A4B_API_BASE_URL ?? "",
								wsUrl: process.env.REACT_APP_A4B_WS_URL ?? "",
								turnConfig: {
									iceServers: [
										{
											urls: "turn:turn-hml.a4b.dev.br:3478",
											username: "guest",
											credential: "guest",
										},
										{
											urls: "turn:turn-hml.a4b.dev.br:5349",
											username: "guest",
											credential: "guest",
										},
									],
								},
								video: {
									frameRate: 15,
									resolution: 240,
								},
								provider: "local",
							}}
							events={{
								end: {
									backButton: true,
									backButtonClick: () => {
										navigate(`/assembleia/${assembleiaId}`);
									},
								},
							}}
							theme={{
								logo: `${process.env.PUBLIC_URL}/von_meeting_logo_on_white.svg`,
								primary: "#C2F94B",
								primaryText: "#272F31",
								secondary: "#272F31",
								secondaryText: "#FFFFFF",
								danger: "#EB5757",
								dangerText: "#272F31",
								warning: "#F2C94C",
								warningText: "#272F31",
								disabled: "#BDBDBD",
								disabledText: "#272F31",
								// background: "#272F31",
								// backgroundSidebar: "#fff",
								// backgroundControl: "#3B4143",
							}}
						/>
					</div>
				</>
			) : (
				<div className="d-flex align-items-center">
					<div className="d-flex justify-content-center align-items-center flex-column" style={{ width: 400 }}>
						<Image className="mb-5" src={process.env.PUBLIC_URL + "/von_meeting_logo_on_white.svg"} style={{ height: 60 }} />
						<div className="fw-bold mb-4 fs-4">{ato?.descricao}</div>
						<FloatingLabel controlId="nome" label="Seu Nome" className="mb-4 w-100">
							<Form.Control
								type="text"
								placeholder="Informe aqui o seu nome"
								value={formNome}
								onChange={(event) => {
									setFormNome(event.target.value);
									localStorage.setItem("chamadaConvidadoNome", event.target.value);
								}}
							/>
						</FloatingLabel>
						<Button
							type="button"
							size="lg"
							className="w-100"
							style={{ flex: 1, borderRadius: 100 }}
							onClick={() => {
								setFormStatus(0);
							}}
							disabled={formNome.length === 0 || isLoading}
						>
							{isLoading ? <Spinner animation="border" size="sm" /> : "Entrar"}
						</Button>
					</div>
				</div>
			)}
		</div>
	);
}

function generateUUID() {
	var d = new Date().getTime();
	var d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0;
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = Math.random() * 16;
		if (d > 0) {
			r = (d + r) % 16 | 0;
			d = Math.floor(d / 16);
		} else {
			r = (d2 + r) % 16 | 0;
			d2 = Math.floor(d2 / 16);
		}
		return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
	});
}
