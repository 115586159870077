import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import A4BMeetingToolkit from "@a4b/a4b-meeting-toolkit";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import { AssembleiaGetResponseEntity } from "../../entities/AssembleiaEntity";
import AssembleiaService from "../../services/AssembleiaService";

enum tipoPergunta {
	Multiplas = "representative",
}

export default function Chamada() {
	const assembleiaService = new AssembleiaService();

	const navigate = useNavigate();
	const { assembleiaId, atoId } = useParams();
	const { user, handleLogout } = useAuth();
	const { handleToast } = useToast();

	const [data, setData] = useState<AssembleiaGetResponseEntity["Data"]>();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		assembleiaService.get({ assembleiaId: Number(assembleiaId) }).then((resp: AssembleiaGetResponseEntity) => {
			if (resp.Result === 1 && resp.Data) {
				setData(resp.Data);
				setIsLoading(false);
			} else {
				if (resp.Result === 99) {
					handleLogout();
					navigate("/");
				}
				handleToast("Chamada", resp.Message, 5000, "warning");
				handleVoltar();
			}
		});
	}, []);

	function handleVoltar() {
		navigate(-1);
	}

	const ato = data?.atos.find((ato) => {
		return ato.id === Number(atoId);
	});
	const descricao = `${data?.clientes[0].clienteNome} ${data && data.clientes.length > 1 ? "e outros" : ""} - ${ato?.descricao}`;
	let grupos: string[] = [];
	if (user?.usuario.grupoId) {
		grupos =
			data?.clientes.map((cliente) => {
				return cliente.clienteNome;
			}) || [];
	} else {
		data?.clientes.forEach((cliente) => {
			if (
				cliente.classes.some((classe) => {
					return classe.credores.some((credor) => {
						return credor.representando;
					});
				})
			) {
				if (!grupos.includes(cliente.clienteNome)) {
					grupos.push(cliente.clienteNome);
				}
			}
		});
	}
	const perguntas = ato?.perguntas.map((pergunta) => {
		return {
			externalId: String(pergunta.id),
			text: pergunta.texto,
			voterType: tipoPergunta[pergunta.tipoPergunta as keyof typeof tipoPergunta],
			options: pergunta.opcoes.map((opcao) => {
				return {
					id: String(opcao.id),
					text: opcao.nome,
				};
			}),
			group: `${pergunta.clienteNome}`,
			tag: pergunta.tag,
		};
	});
	const tags = data?.quoruns.map((quorun) => {
		return quorun.valor;
	});

	return (
		<div className="d-flex" style={{ minHeight: "100dvh" }}>
			{isLoading && (
				<div className="position-absolute d-flex justify-content-center align-items-center flex-column bg-white" style={{ inset: 0, zIndex: 100 }}>
					<Spinner className="mb-2" />
					Carregando
				</div>
			)}
			{!isLoading && (
				<div style={{ display: "flex", flex: 1, justifyContent: "center", position: "relative" }}>
					<A4BMeetingToolkit
						id={String(ato?.chamadaId)}
						token={String(ato?.chamadaToken)}
						title={descricao}
						participant={{
							id: String(user?.usuario.id),
							name: String(user?.usuario.nome),
							email: String(user?.usuario.email),
							role: user?.usuario.grupoId ? "broadcaster" : "viewer",
						}}
						polls={{
							polls: perguntas,
							config: {
								groups: grupos,
								groupsPlaceholder: "Empresa",
								tags: tags,
								tagsPlaceholder: "Classe",
								voterTypeDefault: "representative",
								voterTypes: false,
							},
						}}
						features={{
							chat: true,
							polls: true,
							documents: true,
							participants: true,
							block: true,
							pip: true,
							screenShare: user?.usuario.grupoId ? true : false,
						}}
						config={{
							apiUrl: process.env.REACT_APP_A4B_API_BASE_URL ?? "",
							wsUrl: process.env.REACT_APP_A4B_WS_URL ?? "",
							turnConfig: {
								iceServers: [
									{
										urls: "turn:turn-hml.a4b.dev.br:3478",
										username: "guest",
										credential: "guest",
									},
									{
										urls: "turn:turn-hml.a4b.dev.br:5349",
										username: "guest",
										credential: "guest",
									},
								],
							},
							video: {
								frameRate: 15,
								resolution: 240,
							},
							provider: "local",
						}}
						events={{
							end: {
								backButton: true,
								backButtonClick: () => {
									navigate(`/assembleia/${assembleiaId}`);
								},
							},
						}}
						theme={{
							logo: `${process.env.PUBLIC_URL}/von_meeting_logo_on_white.svg`,
							primary: "#C2F94B",
							primaryText: "#272F31",
							secondary: "#272F31",
							secondaryText: "#FFFFFF",
							danger: "#EB5757",
							dangerText: "#272F31",
							warning: "#F2C94C",
							warningText: "#272F31",
							disabled: "#BDBDBD",
							disabledText: "#272F31",
							// background: "#272F31",
							// backgroundSidebar: "#fff",
							// backgroundControl: "#3B4143",
						}}
					/>
				</div>
			)}
		</div>
	);
}
